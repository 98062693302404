<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card>
        <v-row class="ma-0 pa-0">
          <v-col
            cols="3"
            style="max-widt0h: 250px"
          >
            <v-btn
              class="mt-2"
              color="primary"
              @click="editItem(null)"
            >
              + DODAJ UŻYTKOWNIKA
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              :prepend-inner-icon="icons.mdiMagnify"
              class="mt-2"
              clearable
              dense
              outlined
              placeholder="Wyszukaj..."
              style="margin-bottom: 0;padding-bottom: 0"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
            md="3"
          >
            <v-autocomplete
              v-model="selectedRole"
              :items="helpers.roles"
              class="mt-2"
              clearable
              dense
              item-text="text"
              item-value="value"
              label="Rola"
              multiple
              outlined
              small-chips
              @change="filterUsers()"
            >
              <template #selection="{ item }">
                <v-chip
                  small
                  :color="item.color"
                >
                  {{ item.text }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-switch
              v-model="markActive"
              dense
              label="Aktywni"
              @change="filterUsers()"
              style="margin-top: 12px;padding-bottom: 0"
            >
            </v-switch>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-data-table
          v-model="selected"
          fixed-header
          :items="users"
          :headers="helpers.userHeaders"
          :loading="dtLoading"
          item-key="id"
          :search="search"
          class="table-rounded"
          no-data-text="Brak użytkowników"
          no-results-text="Nie znaleziono użytkowników spełniających kryteria"
          hide-default-footer
          height="65vh"
        >
          <template #[`item.username`]="{item}">
            <div style="display: flex">
            <v-avatar
              color="#EDE7F6"
              size="35"
            class="mr-2">
              <span class="primary--text text-h7">{{ helpers.genAvatar(item.name, item.surname) }}</span>
            </v-avatar>
            <div class="d-flex flex-column">
              <span  class="d-block font-weight-semibold text--primary text-truncate">{{ item.name + " " + item.surname}}</span>
              <small>{{ item.username }}</small>
            </div>
            </div>
          </template>
          <template #[`item.role`]="{item}">
            <v-chip
              small
              :color="helpers.getRole(item.role, 'color')"
              class="font-weight-medium"
            >
              {{ helpers.getRole(item.role, 'text')}}
            </v-chip>
          </template>
          <template #[`item.active`]="{item}">
              <v-icon v-if="item.active" color="success">{{ icons.mdiCheck }}</v-icon>
              <v-icon v-if="!item.active" color="error">{{ icons.mdiClose }}</v-icon>
          </template>
          <template #[`item.actions`]="{item}">
            <v-btn
              :class="item.actions"
              icon
              @click="editItem(item.id)"
            >
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
  mdiDotsVertical,
  mdiExportVariant,
  mdiCheck,
  mdiClose,
  mdiMagnify,
  mdiBolt,
  mdiPencilOutline,
  mdiSquareEditOutline
} from '@mdi/js'
import helpers from '@/utils/helpers'
import DeviceService from "@/services/DeviceService";


export default {
  name: 'Users',
  data() {
    return {
      helpers,
      snackbar: false,
      snackbarContent:'',
      snackbarColor: '',
      markActive: true,
      search: '',
      selectedRole: [],
      selected: [],
      users: [],
      dtLoading: false,
      icons: {
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiExportVariant,
        mdiCheck,
        mdiClose,
        mdiBolt
      }
    }
  },
  computed: {
    ...mapState([
      'session'
    ])
  },
  beforeMount() {
    if (!this.$store.state.session.loggedIn) {
      this.$router.push('/login').catch(error => {
        console.info(error.message)
      })
    }
  },
  async created() {
    await this.refreshUsers()
  },
  methods: {
    ...mapActions([
      'login',
      'getUsers'
    ]),
    refreshUsers(filter) {
      if (!filter) {
        DeviceService.getUsers().then(async response => {
          this.users = response.data.data
        })
      } else {
        DeviceService.getUsersFiltered(filter).then(async response => {
          this.users = response.data.data
        })
      }
    },
    filterUsers() {
      this.refreshUsers({role: this.selectedRole, active: this.markActive})
    },
    editItem(id) {
      if (id) {
        this.$router.push('/users/edit/' + id)
      } else {
        this.$router.push('/users/edit/new')
      }
    }
  }
}
</script>

<style scoped>

</style>
