var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticStyle:{"max-widt0h":"250px"},attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(null)}}},[_vm._v(" + DODAJ UŻYTKOWNIKA ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"mt-2",staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"clearable":"","dense":"","outlined":"","placeholder":"Wyszukaj..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.helpers.roles,"clearable":"","dense":"","item-text":"text","item-value":"value","label":"Rola","multiple":"","outlined":"","small-chips":""},on:{"change":function($event){return _vm.filterUsers()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1),_c('v-col',[_c('v-switch',{staticStyle:{"margin-top":"12px","padding-bottom":"0"},attrs:{"dense":"","label":"Aktywni"},on:{"change":function($event){return _vm.filterUsers()}},model:{value:(_vm.markActive),callback:function ($$v) {_vm.markActive=$$v},expression:"markActive"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"table-rounded",attrs:{"fixed-header":"","items":_vm.users,"headers":_vm.helpers.userHeaders,"loading":_vm.dtLoading,"item-key":"id","search":_vm.search,"no-data-text":"Brak użytkowników","no-results-text":"Nie znaleziono użytkowników spełniających kryteria","hide-default-footer":"","height":"65vh"},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"#EDE7F6","size":"35"}},[_c('span',{staticClass:"primary--text text-h7"},[_vm._v(_vm._s(_vm.helpers.genAvatar(item.name, item.surname)))])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name + " " + item.surname))]),_c('small',[_vm._v(_vm._s(item.username))])])],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.helpers.getRole(item.role, 'color')}},[_vm._v(" "+_vm._s(_vm.helpers.getRole(item.role, 'text'))+" ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.icons.mdiCheck))]):_vm._e(),(!item.active)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.icons.mdiClose))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:item.actions,attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }